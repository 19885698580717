@font-face {
  font-family: MatterWeb;
  src: url("./fonts/Matter-Regular.woff2") format("woff2"),
    url("./fonts/Matter-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: MatterWeb;
  src: url("./fonts/Matter-SemiBold.woff2") format("woff2"),
    url("./fonts/Matter-SemiBold.woff") format("woff");
  font-style: normal;
  font-weight: bold;
  text-rendering: optimizeLegibility;
}
