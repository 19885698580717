@font-face {
  font-family: "icomoon";
  src: url("/fonts/icomoon.eot?cks5aa");
  src: url("/fonts/icomoon.eot?cks5aa#iefix") format("embedded-opentype"),
    url("/fonts/icomoon.ttf?cks5aa") format("truetype"),
    url("/fonts/icomoon.woff?cks5aa") format("woff"),
    url("/fonts/icomoon.svg?cks5aa#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-md-question:before {
  content: "\ea19";
  color: #4a5568;
}
.icon-md-star:before {
  content: "\ea1a";
  color: #4a5568;
}
.icon-sm-send:before {
  content: "\ea18";
  color: #4a5568;
}
.icon-md-adjustments:before {
  content: "\e900";
  color: #4a5568;
}
.icon-md-annotation:before {
  content: "\e901";
  color: #4a5568;
}
.icon-md-archive:before {
  content: "\e902";
  color: #4a5568;
}
.icon-md-arrow-circle-down:before {
  content: "\e903";
  color: #4a5568;
}
.icon-md-arrow-circle-left:before {
  content: "\e904";
  color: #4a5568;
}
.icon-md-arrow-circle-right:before {
  content: "\e905";
  color: #4a5568;
}
.icon-md-arrow-circle-up:before {
  content: "\e906";
  color: #4a5568;
}
.icon-md-arrow-down:before {
  content: "\e907";
  color: #4a5568;
}
.icon-md-arrow-left:before {
  content: "\e908";
  color: #4a5568;
}
.icon-md-arrow-narrow-down:before {
  content: "\e909";
  color: #4a5568;
}
.icon-md-arrow-narrow-left:before {
  content: "\e90a";
  color: #4a5568;
}
.icon-md-arrow-narrow-right:before {
  content: "\e90b";
  color: #4a5568;
}
.icon-md-arrow-narrow-up:before {
  content: "\e90c";
  color: #4a5568;
}
.icon-md-arrow-right:before {
  content: "\e90d";
  color: #4a5568;
}
.icon-md-arrow-up:before {
  content: "\e90e";
  color: #4a5568;
}
.icon-md-at-symbol:before {
  content: "\e90f";
  color: #4a5568;
}
.icon-md-bell:before {
  content: "\e910";
  color: #4a5568;
}
.icon-md-book-open:before {
  content: "\e911";
  color: #4a5568;
}
.icon-md-bookmark:before {
  content: "\e912";
  color: #4a5568;
}
.icon-md-briefcase:before {
  content: "\e913";
  color: #4a5568;
}
.icon-md-calendar:before {
  content: "\e914";
  color: #4a5568;
}
.icon-md-camera:before {
  content: "\e915";
  color: #4a5568;
}
.icon-md-cash:before {
  content: "\e916";
  color: #4a5568;
}
.icon-md-chart-pie:before {
  content: "\e917";
  color: #4a5568;
}
.icon-md-chat:before {
  content: "\e918";
  color: #4a5568;
}
.icon-md-check-circle:before {
  content: "\e919";
  color: #4a5568;
}
.icon-md-check:before {
  content: "\e91a";
  color: #4a5568;
}
.icon-md-cheveron-down:before {
  content: "\e91b";
  color: #4a5568;
}
.icon-md-cheveron-left:before {
  content: "\e91c";
  color: #4a5568;
}
.icon-md-cheveron-right:before {
  content: "\e91d";
  color: #4a5568;
}
.icon-md-cheveron-up:before {
  content: "\e91e";
  color: #4a5568;
}
.icon-md-clipboard-check:before {
  content: "\e91f";
  color: #4a5568;
}
.icon-md-clipboard-copy:before {
  content: "\e920";
  color: #4a5568;
}
.icon-md-clipboard-list:before {
  content: "\e921";
  color: #4a5568;
}
.icon-md-clipboard:before {
  content: "\e922";
  color: #4a5568;
}
.icon-md-clock:before {
  content: "\e923";
  color: #4a5568;
}
.icon-md-cloud-download:before {
  content: "\e924";
  color: #4a5568;
}
.icon-md-cloud-upload:before {
  content: "\e925";
  color: #4a5568;
}
.icon-md-code:before {
  content: "\e926";
  color: #4a5568;
}
.icon-md-cog:before {
  content: "\e927";
  color: #4a5568;
}
.icon-md-collection:before {
  content: "\e928";
  color: #4a5568;
}
.icon-md-color-swatch:before {
  content: "\e929";
  color: #4a5568;
}
.icon-md-credit-card:before {
  content: "\e92a";
  color: #4a5568;
}
.icon-md-currency-dollar:before {
  content: "\e92b";
  color: #4a5568;
}
.icon-md-currency-euro:before {
  content: "\e92c";
  color: #4a5568;
}
.icon-md-currency-pound:before {
  content: "\e92d";
  color: #4a5568;
}
.icon-md-currency-rupee:before {
  content: "\e92e";
  color: #4a5568;
}
.icon-md-currency-yen:before {
  content: "\e92f";
  color: #4a5568;
}
.icon-md-document-add:before {
  content: "\e930";
  color: #4a5568;
}
.icon-md-document-download:before {
  content: "\e931";
  color: #4a5568;
}
.icon-md-document-duplicate:before {
  content: "\e932";
  color: #4a5568;
}
.icon-md-document-remove:before {
  content: "\e933";
  color: #4a5568;
}
.icon-md-document:before {
  content: "\e934";
  color: #4a5568;
}
.icon-md-dots-circle-horizontal:before {
  content: "\e935";
  color: #4a5568;
}
.icon-md-dots-horizontal:before {
  content: "\e936";
  color: #4a5568;
}
.icon-md-dots-vertical:before {
  content: "\e937";
  color: #4a5568;
}
.icon-md-download:before {
  content: "\e938";
  color: #4a5568;
}
.icon-md-duplicate:before {
  content: "\e939";
  color: #4a5568;
}
.icon-md-emoji-happy:before {
  content: "\e93a";
  color: #4a5568;
}
.icon-md-emoji-sad:before {
  content: "\e93b";
  color: #4a5568;
}
.icon-md-exclamation-circle:before {
  content: "\e93c";
  color: #4a5568;
}
.icon-md-exclamation:before {
  content: "\e93d";
  color: #4a5568;
}
.icon-md-external-link:before {
  content: "\e93e";
  color: #4a5568;
}
.icon-md-eye:before {
  content: "\e93f";
  color: #4a5568;
}
.icon-md-filter:before {
  content: "\e940";
  color: #4a5568;
}
.icon-md-flag:before {
  content: "\e941";
  color: #4a5568;
}
.icon-md-folder:before {
  content: "\e942";
  color: #4a5568;
}
.icon-md-globe-alt:before {
  content: "\e943";
  color: #4a5568;
}
.icon-md-globe:before {
  content: "\e944";
  color: #4a5568;
}
.icon-md-hashtag:before {
  content: "\e945";
  color: #4a5568;
}
.icon-md-heart:before {
  content: "\e946";
  color: #4a5568;
}
.icon-md-home:before {
  content: "\e947";
  color: #4a5568;
}
.icon-md-inbox-in:before {
  content: "\e948";
  color: #4a5568;
}
.icon-md-inbox:before {
  content: "\e949";
  color: #4a5568;
}
.icon-md-information-circle:before {
  content: "\e94a";
  color: #4a5568;
}
.icon-md-light-bulb:before {
  content: "\e94b";
  color: #4a5568;
}
.icon-md-lightning-bolt:before {
  content: "\e94c";
  color: #4a5568;
}
.icon-md-link:before {
  content: "\e94d";
  color: #4a5568;
}
.icon-md-location-marker:before {
  content: "\e94e";
  color: #4a5568;
}
.icon-md-lock-closed:before {
  content: "\e94f";
  color: #4a5568;
}
.icon-md-lock-open:before {
  content: "\e950";
  color: #4a5568;
}
.icon-md-mail-open:before {
  content: "\e951";
  color: #4a5568;
}
.icon-md-mail:before {
  content: "\e952";
  color: #4a5568;
}
.icon-md-menu-alt-1:before {
  content: "\e953";
  color: #4a5568;
}
.icon-md-menu-alt-2:before {
  content: "\e954";
  color: #4a5568;
}
.icon-md-menu-alt-3:before {
  content: "\e955";
  color: #4a5568;
}
.icon-md-menu-alt-4:before {
  content: "\e956";
  color: #4a5568;
}
.icon-md-menu:before {
  content: "\e957";
  color: #4a5568;
}
.icon-md-minus-circle:before {
  content: "\e958";
  color: #4a5568;
}
.icon-md-moon:before {
  content: "\e959";
  color: #4a5568;
}
.icon-md-office-building:before {
  content: "\e95a";
  color: #4a5568;
}
.icon-md-paper-clip:before {
  content: "\e95b";
  color: #4a5568;
}
.icon-md-pencil-alt:before {
  content: "\e95c";
  color: #4a5568;
}
.icon-md-pencil:before {
  content: "\e95d";
  color: #4a5568;
}
.icon-md-phone-incoming:before {
  content: "\e95e";
  color: #4a5568;
}
.icon-md-phone-outgoing:before {
  content: "\e95f";
  color: #4a5568;
}
.icon-md-phone:before {
  content: "\e960";
  color: #4a5568;
}
.icon-md-photograph:before {
  content: "\e961";
  color: #4a5568;
}
.icon-md-plus-circle:before {
  content: "\e962";
  color: #4a5568;
}
.icon-md-plus:before {
  content: "\e963";
  color: #4a5568;
}
.icon-md-printer:before {
  content: "\e964";
  color: #4a5568;
}
.icon-md-qrcode:before {
  content: "\e965";
  color: #4a5568;
}
.icon-md-question-mark-circle:before {
  content: "\e966";
  color: #4a5568;
}
.icon-md-receipt-refund:before {
  content: "\e967";
  color: #4a5568;
}
.icon-md-refresh:before {
  content: "\e968";
  color: #4a5568;
}
.icon-md-reply:before {
  content: "\e969";
  color: #4a5568;
}
.icon-md-scale:before {
  content: "\e96a";
  color: #4a5568;
}
.icon-md-search:before {
  content: "\e96b";
  color: #4a5568;
}
.icon-md-selector:before {
  content: "\e96c";
  color: #4a5568;
}
.icon-md-share:before {
  content: "\e96d";
  color: #4a5568;
}
.icon-md-shield-check:before {
  content: "\e96e";
  color: #4a5568;
}
.icon-md-shield-exclamation:before {
  content: "\e96f";
  color: #4a5568;
}
.icon-md-sort-ascending:before {
  content: "\e970";
  color: #4a5568;
}
.icon-md-sort-descending:before {
  content: "\e971";
  color: #4a5568;
}
.icon-md-sparkles:before {
  content: "\e972";
  color: #4a5568;
}
.icon-md-sun:before {
  content: "\e973";
  color: #4a5568;
}
.icon-md-switch-horizontal:before {
  content: "\e974";
  color: #4a5568;
}
.icon-md-switch-vertical:before {
  content: "\e975";
  color: #4a5568;
}
.icon-md-tag:before {
  content: "\e976";
  color: #4a5568;
}
.icon-md-template:before {
  content: "\e977";
  color: #4a5568;
}
.icon-md-ticket:before {
  content: "\e978";
  color: #4a5568;
}
.icon-md-translate:before {
  content: "\e979";
  color: #4a5568;
}
.icon-md-trash:before {
  content: "\e97a";
  color: #4a5568;
}
.icon-md-trending-down:before {
  content: "\e97b";
  color: #4a5568;
}
.icon-md-trending-up:before {
  content: "\e97c";
  color: #4a5568;
}
.icon-md-upload:before {
  content: "\e97d";
  color: #4a5568;
}
.icon-md-user-add:before {
  content: "\e97e";
  color: #4a5568;
}
.icon-md-user-circle:before {
  content: "\e97f";
  color: #4a5568;
}
.icon-md-user-group:before {
  content: "\e980";
  color: #4a5568;
}
.icon-md-user-remove:before {
  content: "\e981";
  color: #4a5568;
}
.icon-md-user:before {
  content: "\e982";
  color: #4a5568;
}
.icon-md-users:before {
  content: "\e983";
  color: #4a5568;
}
.icon-md-view-boards:before {
  content: "\e984";
  color: #4a5568;
}
.icon-md-view-list:before {
  content: "\e985";
  color: #4a5568;
}
.icon-md-volume-off:before {
  content: "\e986";
  color: #4a5568;
}
.icon-md-volume-up:before {
  content: "\e987";
  color: #4a5568;
}
.icon-md-x-circle:before {
  content: "\e988";
  color: #4a5568;
}
.icon-md-x:before {
  content: "\e989";
  color: #4a5568;
}
.icon-md-zoom-in:before {
  content: "\e98a";
  color: #4a5568;
}
.icon-md-zoom-out:before {
  content: "\e98b";
  color: #4a5568;
}
.icon-sm-adjustments:before {
  content: "\e98c";
  color: #4a5568;
}
.icon-sm-annotation:before {
  content: "\e98d";
  color: #4a5568;
}
.icon-sm-archive:before {
  content: "\e98e";
  color: #4a5568;
}
.icon-sm-arrow-circle-down:before {
  content: "\e98f";
  color: #4a5568;
}
.icon-sm-arrow-circle-left:before {
  content: "\e990";
  color: #4a5568;
}
.icon-sm-arrow-circle-right:before {
  content: "\e991";
  color: #4a5568;
}
.icon-sm-arrow-circle-up:before {
  content: "\e992";
  color: #4a5568;
}
.icon-sm-arrow-down:before {
  content: "\e993";
  color: #4a5568;
}
.icon-sm-arrow-left:before {
  content: "\e994";
  color: #4a5568;
}
.icon-sm-arrow-narrow-down:before {
  content: "\e995";
  color: #4a5568;
}
.icon-sm-arrow-narrow-left:before {
  content: "\e996";
  color: #4a5568;
}
.icon-sm-arrow-narrow-right:before {
  content: "\e997";
  color: #4a5568;
}
.icon-sm-arrow-narrow-up:before {
  content: "\e998";
  color: #4a5568;
}
.icon-sm-arrow-right:before {
  content: "\e999";
  color: #4a5568;
}
.icon-sm-arrow-up:before {
  content: "\e99a";
  color: #4a5568;
}
.icon-sm-at-symbol:before {
  content: "\e99b";
  color: #4a5568;
}
.icon-sm-bell:before {
  content: "\e99c";
  color: #4a5568;
}
.icon-sm-book-open:before {
  content: "\e99d";
  color: #4a5568;
}
.icon-sm-bookmark:before {
  content: "\e99e";
  color: #4a5568;
}
.icon-sm-briefcase:before {
  content: "\e99f";
  color: #4a5568;
}
.icon-sm-calendar:before {
  content: "\e9a0";
  color: #4a5568;
}
.icon-sm-camera:before {
  content: "\e9a1";
  color: #4a5568;
}
.icon-sm-cash:before {
  content: "\e9a2";
  color: #4a5568;
}
.icon-sm-chart-pie:before {
  content: "\e9a3";
  color: #4a5568;
}
.icon-sm-chat:before {
  content: "\e9a4";
  color: #4a5568;
}
.icon-sm-check-circle:before {
  content: "\e9a5";
  color: #4a5568;
}
.icon-sm-check:before {
  content: "\e9a6";
  color: #4a5568;
}
.icon-sm-cheveron-down:before {
  content: "\e9a7";
  color: #4a5568;
}
.icon-sm-cheveron-left:before {
  content: "\e9a8";
  color: #4a5568;
}
.icon-sm-cheveron-right:before {
  content: "\e9a9";
  color: #4a5568;
}
.icon-sm-cheveron-up:before {
  content: "\e9aa";
  color: #4a5568;
}
.icon-sm-clipboard-check:before {
  content: "\e9ab";
  color: #4a5568;
}
.icon-sm-clipboard-copy:before {
  content: "\e9ac";
  color: #4a5568;
}
.icon-sm-clipboard-list:before {
  content: "\e9ad";
  color: #4a5568;
}
.icon-sm-clipboard:before {
  content: "\e9ae";
  color: #4a5568;
}
.icon-sm-clock:before {
  content: "\e9af";
  color: #4a5568;
}
.icon-sm-cloud-download:before {
  content: "\e9b0";
  color: #4a5568;
}
.icon-sm-cloud-upload:before {
  content: "\e9b1";
  color: #4a5568;
}
.icon-sm-code:before {
  content: "\e9b2";
  color: #4a5568;
}
.icon-sm-cog:before {
  content: "\e9b3";
  color: #4a5568;
}
.icon-sm-collection:before {
  content: "\e9b4";
  color: #4a5568;
}
.icon-sm-color-swatch:before {
  content: "\e9b5";
  color: #4a5568;
}
.icon-sm-credit-card:before {
  content: "\e9b6";
  color: #4a5568;
}
.icon-sm-currency-dollar:before {
  content: "\e9b7";
  color: #4a5568;
}
.icon-sm-currency-euro:before {
  content: "\e9b8";
  color: #4a5568;
}
.icon-sm-currency-pound:before {
  content: "\e9b9";
  color: #4a5568;
}
.icon-sm-currency-rupee:before {
  content: "\e9ba";
  color: #4a5568;
}
.icon-sm-currency-yen:before {
  content: "\e9bb";
  color: #4a5568;
}
.icon-sm-document-add:before {
  content: "\e9bc";
  color: #4a5568;
}
.icon-sm-document-download:before {
  content: "\e9bd";
  color: #4a5568;
}
.icon-sm-document-duplicate:before {
  content: "\e9be";
  color: #4a5568;
}
.icon-sm-document-remove:before {
  content: "\e9bf";
  color: #4a5568;
}
.icon-sm-document:before {
  content: "\e9c0";
  color: #4a5568;
}
.icon-sm-dots-circle-horizontal:before {
  content: "\e9c1";
  color: #4a5568;
}
.icon-sm-dots-horizontal:before {
  content: "\e9c2";
  color: #4a5568;
}
.icon-sm-dots-vertical:before {
  content: "\e9c3";
  color: #4a5568;
}
.icon-sm-download:before {
  content: "\e9c4";
  color: #4a5568;
}
.icon-sm-duplicate:before {
  content: "\e9c5";
  color: #4a5568;
}
.icon-sm-emoji-happy:before {
  content: "\e9c6";
  color: #4a5568;
}
.icon-sm-emoji-sad:before {
  content: "\e9c7";
  color: #4a5568;
}
.icon-sm-exclamation-circle:before {
  content: "\e9c8";
  color: #4a5568;
}
.icon-sm-exclamation:before {
  content: "\e9c9";
  color: #4a5568;
}
.icon-sm-external-link:before {
  content: "\e9ca";
  color: #4a5568;
}
.icon-sm-eye:before {
  content: "\e9cb";
  color: #4a5568;
}
.icon-sm-filter:before {
  content: "\e9cc";
  color: #4a5568;
}
.icon-sm-flag:before {
  content: "\e9cd";
  color: #4a5568;
}
.icon-sm-folder:before {
  content: "\e9ce";
  color: #4a5568;
}
.icon-sm-globe-alt:before {
  content: "\e9cf";
  color: #4a5568;
}
.icon-sm-globe:before {
  content: "\e9d0";
  color: #4a5568;
}
.icon-sm-hashtag:before {
  content: "\e9d1";
  color: #4a5568;
}
.icon-sm-heart:before {
  content: "\e9d2";
  color: #4a5568;
}
.icon-sm-home:before {
  content: "\e9d3";
  color: #4a5568;
}
.icon-sm-inbox-in:before {
  content: "\e9d4";
  color: #4a5568;
}
.icon-sm-inbox:before {
  content: "\e9d5";
  color: #4a5568;
}
.icon-sm-information-circle:before {
  content: "\e9d6";
  color: #4a5568;
}
.icon-sm-light-bulb:before {
  content: "\e9d7";
  color: #4a5568;
}
.icon-sm-lightning-bolt:before {
  content: "\e9d8";
  color: #4a5568;
}
.icon-sm-link:before {
  content: "\e9d9";
  color: #4a5568;
}
.icon-sm-location-marker:before {
  content: "\e9da";
  color: #4a5568;
}
.icon-sm-lock-closed:before {
  content: "\e9db";
  color: #4a5568;
}
.icon-sm-lock-open:before {
  content: "\e9dc";
  color: #4a5568;
}
.icon-sm-mail-open:before {
  content: "\e9dd";
  color: #4a5568;
}
.icon-sm-mail:before {
  content: "\e9de";
  color: #4a5568;
}
.icon-sm-menu-alt-1:before {
  content: "\e9df";
  color: #4a5568;
}
.icon-sm-menu-alt-2:before {
  content: "\e9e0";
  color: #4a5568;
}
.icon-sm-menu-alt-3:before {
  content: "\e9e1";
  color: #4a5568;
}
.icon-sm-menu-alt-4:before {
  content: "\e9e2";
  color: #4a5568;
}
.icon-sm-menu:before {
  content: "\e9e3";
  color: #4a5568;
}
.icon-sm-minus-circle:before {
  content: "\e9e4";
  color: #4a5568;
}
.icon-sm-moon:before {
  content: "\e9e5";
  color: #4a5568;
}
.icon-sm-office-building:before {
  content: "\e9e6";
  color: #4a5568;
}
.icon-sm-paper-clip:before {
  content: "\e9e7";
  color: #4a5568;
}
.icon-sm-pencil-alt:before {
  content: "\e9e8";
  color: #4a5568;
}
.icon-sm-pencil:before {
  content: "\e9e9";
  color: #4a5568;
}
.icon-sm-phone-incoming:before {
  content: "\e9ea";
  color: #4a5568;
}
.icon-sm-phone-outgoing:before {
  content: "\e9eb";
  color: #4a5568;
}
.icon-sm-phone:before {
  content: "\e9ec";
  color: #4a5568;
}
.icon-sm-photograph:before {
  content: "\e9ed";
  color: #4a5568;
}
.icon-sm-plus-circle:before {
  content: "\e9ee";
  color: #4a5568;
}
.icon-sm-plus:before {
  content: "\e9ef";
  color: #4a5568;
}
.icon-sm-printer:before {
  content: "\e9f0";
  color: #4a5568;
}
.icon-sm-qrcode:before {
  content: "\e9f1";
  color: #4a5568;
}
.icon-sm-question-mark-circle:before {
  content: "\e9f2";
  color: #4a5568;
}
.icon-sm-receipt-refund:before {
  content: "\e9f3";
  color: #4a5568;
}
.icon-sm-refresh:before {
  content: "\e9f4";
  color: #4a5568;
}
.icon-sm-reply:before {
  content: "\e9f5";
  color: #4a5568;
}
.icon-sm-scale:before {
  content: "\e9f6";
  color: #4a5568;
}
.icon-sm-search:before {
  content: "\e9f7";
  color: #4a5568;
}
.icon-sm-selector:before {
  content: "\e9f8";
  color: #4a5568;
}
.icon-sm-share:before {
  content: "\e9f9";
  color: #4a5568;
}
.icon-sm-shield-check:before {
  content: "\e9fa";
  color: #4a5568;
}
.icon-sm-shield-exclamation:before {
  content: "\e9fb";
  color: #4a5568;
}
.icon-sm-sort-ascending:before {
  content: "\e9fc";
  color: #4a5568;
}
.icon-sm-sort-descending:before {
  content: "\e9fd";
  color: #4a5568;
}
.icon-sm-sparkles:before {
  content: "\e9fe";
  color: #4a5568;
}
.icon-sm-sun:before {
  content: "\e9ff";
  color: #4a5568;
}
.icon-sm-switch-horizontal:before {
  content: "\ea00";
  color: #4a5568;
}
.icon-sm-switch-vertical:before {
  content: "\ea01";
  color: #4a5568;
}
.icon-sm-tag:before {
  content: "\ea02";
  color: #4a5568;
}
.icon-sm-template:before {
  content: "\ea03";
  color: #4a5568;
}
.icon-sm-ticket:before {
  content: "\ea04";
  color: #4a5568;
}
.icon-sm-translate:before {
  content: "\ea05";
  color: #4a5568;
}
.icon-sm-trash:before {
  content: "\ea06";
  color: #4a5568;
}
.icon-sm-trending-down:before {
  content: "\ea07";
  color: #4a5568;
}
.icon-sm-trending-up:before {
  content: "\ea08";
  color: #4a5568;
}
.icon-sm-upload:before {
  content: "\ea09";
  color: #4a5568;
}
.icon-sm-user-add:before {
  content: "\ea0a";
  color: #4a5568;
}
.icon-sm-user-circle:before {
  content: "\ea0b";
  color: #4a5568;
}
.icon-sm-user-group:before {
  content: "\ea0c";
  color: #4a5568;
}
.icon-sm-user-remove:before {
  content: "\ea0d";
  color: #4a5568;
}
.icon-sm-user:before {
  content: "\ea0e";
  color: #4a5568;
}
.icon-sm-users:before {
  content: "\ea0f";
  color: #4a5568;
}
.icon-sm-view-boards:before {
  content: "\ea10";
  color: #4a5568;
}
.icon-sm-view-list:before {
  content: "\ea11";
  color: #4a5568;
}
.icon-sm-volume-off:before {
  content: "\ea12";
  color: #4a5568;
}
.icon-sm-volume-up:before {
  content: "\ea13";
  color: #4a5568;
}
.icon-sm-x-circle:before {
  content: "\ea14";
  color: #4a5568;
}
.icon-sm-x:before {
  content: "\ea15";
  color: #4a5568;
}
.icon-sm-zoom-in:before {
  content: "\ea16";
  color: #4a5568;
}
.icon-sm-zoom-out:before {
  content: "\ea17";
  color: #4a5568;
}
