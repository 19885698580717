@import "fonts.scss";
@import "icomoon.css";

.ql-editor {
  height: 150px;
}
.centered {
  text-align: center;
}

/* FORM CUSTOMISATION */
.FloatingLabel  {
  text-transform: uppercase !important;
}

.MuiFormControl-root {
  &.NoMargins {
    margin: 0px !important;
  }
}

.MuiInputBase-root {
  margin-top: 10px !important;
  margin-bottom: 12px;
  border-radius: 5px !important;

  .MuiOutlinedInput-input {
    padding: 8.5px 14px !important;
  }
}

.ChipCheckBoxes {
  .MuiChip-root {
    margin-right: 6px;
    &:last-child {
      margin-right: 0;
    }

    &.MuiChip-colorSecondary {
      border: 1px solid #4b4b4b;
    }

    &.MuiChip-outlinedSecondary {
      color: #b4b4b7;
      border-color: #b4b4b7;
    }
  }
}

/* FORM CUSTOMISATION */

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .AppBar {
    position: fixed;
    .Breadcrumbs {
      margin-left: 2rem;

      li {
        &.MuiBreadcrumbs-separator {
          color: #b4b4b7;
        }
        a {
          font-size: 0.875rem;
          color: #b4b4b7;
          font-weight: normal;
          text-decoration: none;

          &:hover {
            color: #fff;
          }
        }
        p {
          font-size: 0.875rem;
          color: #ffffff;
          font-weight: 500;
        }
      }
    }
  }

  .NavBarWrap {
    border-bottom: 2px solid #e2e3e4;
    .NavBar {
      margin-top: 64px;
      height: 3.43rem;
      display: flex;
      flex-direction: row;
      margin-bottom: -2px;

      a {
        align-self: stretch;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 0.875rem;
        padding-right: 0.875rem;
        margin-right: 0.75rem;
        color: #686e71;
        font-weight: 500;
        font-size: 0.875rem;
        text-decoration: none;

        &:hover {
          color: #2a2827;
        }
      }
    }
  }

  .Screen {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-top: 2.3rem;
    background-color: #f0f1f4;

    .ContainerGrow {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }

    .PageHeader {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 20px;

      .HeaderButton {
        margin-left: 20px;
      }
    }

    &.Login {
      padding: 0;

      h2 {
      }

      h6 {
        font-size: 1rem;
        line-height: 1.56rem;
        color: #686e71;
        margin-top: 0.4rem;
        font-weight: bold;
      }

      .Hint {
        color: #b4b4b7;
        font-size: 0.8125rem;
        line-height: 1.25rem;
        margin-top: 0.7rem;

        a {
          color: #4b4b4b;
          text-decoration: none;
        }
      }

      .YellowBar {
        background: url(img/phones.png);
        background-size: cover;
        position: relative;

        .Overlay {
          background-color: #ffbd2c;
          opacity: 0.9;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }

    &.Home {
      margin-top: 64px;

      .BelongApp {
        background-color: #ffffff;
        border-radius: 20px;
        cursor: pointer;
        height: 100%;

        &:hover {
          background-color: #fbfbfd;
        }

        &.New {
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #e6e7ea;

          h3 {
            color: #b4b4b7;
          }

          &:hover {
            background-color: #dbdcdf;
          }
        }

        .Header {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          .Icon {
            width: 3.75rem;
            height: 3.75rem;
            border-radius: 1rem;
            background-color: #f9885c;
            background-size: cover;
            background-position: center center;
          }

          .Buttons {
            .More {
              margin-left: 5px;
              min-width: 0;
              padding-left: 12px;
              padding-right: 12px;
            }
          }
        }

        h3 {
          margin-top: 1.43rem;

          a {
            text-decoration: none;
            color: inherit;
          }
        }
        p {
          font-size: 0.8125rem;
          margin-top: 0.3rem;
        }
      }
    }

    &.ListPage {
      .DataTable {
        border-radius: 20px;
        margin-bottom: 20px;

        thead tr th {
          font-size: 0.6875rem;
          color: #686e71;
          font-weight: 500;
          text-transform: uppercase;
        }

        tbody tr:last-child .MuiTableCell-root {
          border-bottom: none;
        }

        tbody tr th a {
          font-size: 0.9375rem;
          color: #4b4b4b;
          font-weight: 500;
          text-decoration: none;
        }

        tbody td .Audience {
          margin-right: 10px;
          margin-bottom: 10px;

          &:last-child {
            margin-right: 0;
          }
        }

        tbody td .Audience {
          margin-right: 10px;
          margin-bottom: 10px;
          &:last-child {
            margin-right: 0;
          }
        }

        tr .Icon,
        td .Icon {
          margin-right: 10px;
          color: #4b4b4b;
          font-size: 1.3rem;
          position: relative;
          top: 2px;
        }

        // NOW ONLY FOR MUI DATATABLE (DIVS NOT TDS)
        .Audiences {
          -ms-overflow-style: none;
          /* IE and Edge */
          scrollbar-width: none;
          /* Firefox */
          &::-webkit-scrollbar {
            display: none;
          }

          .Audience {
            margin-right: 5px;
          }
        }
      }

      .VotingPreview,
      .UserRanking {
        border-radius: 10px;
        background-color: #ffffff;

        .Header {
          display: flex;
          flex-direction: row;
          align-items: center;
          font-size: 11px;
          color: #b4b4b7;
          text-transform: uppercase;
          height: 56px;
          padding: 0 18px;
          border-bottom: 1px solid #e2e3e4;
          justify-content: space-between;

          h2 {
            font-size: .6875rem;
            color: #686e71;
            font-weight: 500;
            text-transform: uppercase;
          }

          a {
            color: #b4b4b7;
            text-decoration: none;

            &:hover {
              text-decoration: underline;
            }
          }
          span {
            flex: 1;
            text-align: center;
          }
        }
        iframe {
          height: 310px;
          width: 100%;
          border: none;
          outline: none;
          box-shadow: none;
        }
        ol {
          margin: 0;
          padding: 0;
          li {
            padding: 0 1.25rem;
            height: 3.75rem;
            display: flex;
            align-items: center;
            border-bottom: 1px solid #e2e3e4;

            &:last-child {
              border-bottom: none;
            }

            strong {
              width: 30px;
              color: #a7a7a7;
            }

            .Avatar {
              background-color: #999;
              width: 40px;
              height: 40px;
              border-radius: 50%;
              margin-right: 15px;
            }

            .Info {
              display: flex;
              flex-direction: column;
              flex: 1;

              .Name {
                font-weight: bold;
                color: #333333;
                font-size: 1.07rem;
              }
              .Subtitle {
                font-size: 0.8125rem;
                color: #8d8e8f;
              }
            }

            .Points {
              height: 20px;
              border-radius: 10px;
              background: #ffbd2c;
              color: #fff;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 0.8125rem;
              padding: 0 10px;
            }
          }
        }
      }
    }

    &.Wizard {
      .AppPreview {
        background-color: #f9f9fb;
        border: 1px solid #e2e3e4;
        position: relative;

        .AppMock {
          .Header {
            display: flex;
            position: relative;
            margin-bottom: 1rem;

            .Arrow {
              position: absolute;
              left: 0;
              top: 0;
              .Icon {
                color: #b4b4b7;
              }
            }
            .Title {
              flex-grow: 1;
              text-align: center;
              color: #b4b4b7;
              font-size: 1rem;
              font-weight: 500;
            }
          }
          .Banner {
            background-color: #b4b4b7;
          }
          .Modules {
            padding-top: 35px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;

            .Module {
              background-color: #fff;
              border-radius: 10px;
              width: 27%;
              height: 115px;
              margin-bottom: 20px;
              padding-bottom: 1rem;
              box-sizing: border-box;

              display: flex;
              flex-direction: column;
              align-items: center;

              box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06);

              .Icon {
                font-size: 3rem;
                flex-grow: 1;
              }

              .Label {
                text-align: center;
                color: #b9b9b9;
                font-size: 0.82rem;
              }
            }
          }
          .Footer {
            display: flex;
            flex-direction: row;
            justify-content: center;

            Button {
              color: #fff;
            }
          }
        }
      }
    }
  }

  .Footer {
    color: #fff;
  }
}

.MuiBackdrop-root {
  //z-index: 1200 !important;
}

// Generic language form
.LanguageLess {
  border-top: 1px solid #ebecec;
  border-radius: 0;
}
.Language {
  border-radius: 0;

  .Picker {
    background-color: #fff;
    border-bottom: 1px solid #ebecec;
    .FlagButton {
      border: none;
      outline: none;
      background: transparent;
      padding-bottom: 2px;
      opacity: 0.5;
      margin-right: 5px;

      .Icon {
        font-size: 1.4rem;
        padding: 0 0.6rem 0.5rem 0.6rem;
      }

      &:hover {
        opacity: 1;
      }
      &.active {
        opacity: 1;
        border-bottom: 2px solid #ffbd2c;
        padding-bottom: 0;
      }
    }
  }
}

.RightModal {
  z-index: 1250;
  outline: none;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 640px;
  max-width: 100%;
  background-color: #f7f7f8;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;

  legend {
    margin-bottom: 10px;
  }

  .Center {
    flex-grow: 1;
  }

  .Footer {
    border-top: 1px solid #ebecec;
    border-radius: 0;
    display: flex;
    justify-content: flex-end;

    .SaveButton {
      margin-left: 10px;
    }
  }

  .ModuleTypes {
    .ModuleType {
      border: 1px solid #eeeeef;
      border-radius: 10px;
      padding: 30px;
      cursor: pointer;
      display: flex;
      flex-direction: column;

      &:hover {
        background-color: #fcfcfc;
      }

      .Title {
        display: flex;
        flex-direction: row;

        .Icon {
          font-size: 1.6rem;
          margin-right: 10px;
        }
        h4 {
          font-weight: 500;
          font-size: 1.25rem;
        }
      }

      p {
        color: #686e71;
        font-size: 0.8125rem;
        line-height: 1.125rem;
      }
    }
  }
}

.RoundedForm {
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  background: #ffffff;
  overflow: hidden;
  padding: 0.25rem 0.6875rem;

  input {
    padding: 1rem 0.375rem;
    border: none;
    box-shadow: none;
    outline: none;
    font-size: 1rem;
  }
  input + input {
    border-top: 1px solid #e2e3e4;
  }
}

.muicc-colorpicker-button {
  margin-top: 15px !important;
}

.MuiGridPanel-paper {
  border: 1px solid rgba(224, 224, 224, 1);
}

dl {
  width: 100%;
  overflow: visible;
  padding: 0;
  margin: 0;
  dt {
    float: left;
    width: 30%;
    /* adjust the width; make sure the total of both is 100% */
    padding: 0;
    margin: 0;
  }
  dd {
    font-weight: 200;
    float: left;
    width: 70%;
    /* adjust the width; make sure the total of both is 100% */
    padding: 0;
    margin: 0;
  }
}
